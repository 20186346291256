import "../../../assets/css/custom.css";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Stack,
  Typography,
  Container,
  Paper,
  Avatar,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useRouteMatch, useLocation } from "react-router-dom";
import axios from "axios";
import { Loader } from "components";
import {
  AccountCircleOutlined,
  RateReview,
  CancelOutlined,
  CheckCircle,
  LanguageOutlined,
  LinearScaleOutlined,
  Save,
  PublishedWithChanges,
  PsychologySharp,
} from "@mui/icons-material";
import { confidenceColorLevels, stringAvatar } from "utils/helpers";
import saphireImg from "../../../assets/saphire.png";
import SiccLayout from "./SiccLayout";
import moment from "moment";
import RasaMessageInput from "./RasaMessageInput";
import ProgressWithLabel from "../components/ProgressWithLabel";

const rubyAIUri = process.env.REACT_APP_RUBY_AI_URI;
const rubyConvoUri = process.env.REACT_APP_RUBY_CONVO_URI;

const users = [
  {
    id: 1,
    senderId: "1b66dbcc-1058-4652-bf61-8cbf47e2f97a",
    firstName: "John",
    lastName: "Doe",
    siteName: "ABC Senior Living",
    siteId: "73",
    ipAddress: "101.101.101.101",
  },
  {
    id: 2,
    senderId: "dff4d175-bf64-4a91-a83f-e654dea48a3e",
    firstName: "Bill",
    lastName: "Gates",
    siteName: "AJ Java Joint",
    siteId: "89",
    ipAddress: "101.101.101.102",
  },
  {
    id: 3,
    senderId: "4040c0d5-d6cb-4712-883c-9bed47f247b3",
    firstName: "Elon",
    lastName: "Musk",
    siteName: "ABC Senior Living",
    siteId: "73",
    ipAddress: "101.101.101.103",
  },

  {
    id: 4,
    senderId: "91cd4ea1-8efe-4d87-a556-d325c60c2644",
    firstName: "Emma",
    lastName: "Watson",
    siteName: "AJ Keto Factory",
    siteId: "61",
    ipAddress: "101.101.101.104",
  },
  {
    id: 5,
    senderId: "91cd4ea1-8efe-4d87-a556-d325c60c2644",
    firstName: "Andrew",
    lastName: "Simmons",
    siteName: "ABC Senior Living",
    siteId: "73",
    ipAddress: "101.101.101.105",
  },
];

const RasaMessage = ({
  message,
  timestamp,
  details,
  senderId,
  getConversationDetails,
}) => {
  const [onInputMode, setOnInputMode] = useState(false);
  const [inputMessage, setInputMessage] = useState(message);
  return (
    <Box
      padding={2}
      sx={{
        background: "rgba(0,0,0,.02)",
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          gap: 2,
        }}
      >
        <Stack gap={1}>
          <Avatar src={saphireImg} />
        </Stack>
        {onInputMode ? (
          <RasaMessageInput
            senderId={senderId}
            messageDetails={details}
            onEditMode={onInputMode}
            inputMessage={inputMessage}
            setOnInputMode={setOnInputMode}
            setInputMessage={setInputMessage}
            getConversationDetails={getConversationDetails}
          />
        ) : (
          <Typography width={"100%"}>
            {inputMessage}{" "}
            <Typography
              component="span"
              variant="caption"
              sx={{ fontStyle: "italic", marginLeft: 1 }}
            >
              {timestamp}
            </Typography>
          </Typography>
        )}
        <Stack>
          {!onInputMode && (
            <IconButton color="primary" onClick={() => setOnInputMode(true)}>
              <RateReview />
            </IconButton>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

const ConversationDetails = () => {
  const match = useRouteMatch();
  const { state } = useLocation();
  const senderId = match?.params?.senderId || "";
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState(null);
  console.log(state);
  const messagesEndRef = useRef(null);
  const scrollableRef = useRef(null);

  const getConversationDetails = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${rubyConvoUri}/conversations/` + senderId
      );
      const user = users.find((u) => u.senderId === senderId);
      const conversation = data.map((item) => {
        const date = new Date(item.timestamp);
        item.timestamp = moment.unix(date).fromNow();
        return item;
      });

      setDetails({ ...state, conversation: conversation });
    } catch (error) {
    } finally {
      setLoading(false);
      scrollToLatest();
    }
  }, [senderId, state]);

  const scrollToLatest = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      scrollToLatest();
    }
  }, []);

  useEffect(() => {
    getConversationDetails();
  }, [getConversationDetails]);

  if (loading) {
    return <Loader open={loading} />;
  }

  if (!details) {
    return (
      <Typography>
        No conversation details for this sender ID: {senderId}
      </Typography>
    );
  }
  return (
    <SiccLayout>
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            lg: "row",
          },
          alignItems: "flex-start",
          gap: 2,
          height: "100vh",
          maxHeight: {
            xs: "600px",
            lg: "85vh",
          },
        }}
      >
        <Stack
          gap={2}
          sx={{
            width: "100%",
            maxWidth: {
              xs: "100%",
              lg: 300,
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              minHeight: "10vh",
              boxShadow: 4,
              borderRadius: 2,
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Stack gap={1} direction="row">
              <AccountCircleOutlined color="inherit" />
              <Typography>
                {details.firstName} {details.lastName}
              </Typography>
            </Stack>
            <Stack gap={1} direction="row">
              <LanguageOutlined color="inherit" />
              <Typography>{details.siteName}</Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              width: "100%",

              minHeight: "10vh",
              boxShadow: 4,
              borderRadius: 2,
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Stack gap={1}>
              <Stack gap={1} direction="row">
                <PublishedWithChanges color="inherit" />
                <Typography>Confidence</Typography>
              </Stack>
              <ProgressWithLabel
                value={details.averageConfidence * 100}
                color={confidenceColorLevels(details.averageConfidence)}
              />
            </Stack>
            <Stack gap={1}>
              <Stack gap={1} direction="row">
                <PsychologySharp color="inherit" />
                <Typography>Source</Typography>
              </Stack>
              <ProgressWithLabel
                value={details.openAIAnswersPercentage}
                label={"OpenAI"}
              />
              <ProgressWithLabel
                value={details.rasaIntentAnswersPercentage}
                label={"Rasa"}
                color="#FF007F"
              />
            </Stack>
          </Box>
        </Stack>

        {details?.conversation.length > 0 ? (
          <Box
            ref={scrollableRef}
            width="100%"
            overflow="auto"
            sx={{
              height: {
                xs: "calc(100% - 100px)",
                lg: "calc(100% - 70px)",
              },

              "&::-webkit-scrollbar": {
                width: "10px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "rgba(0,0,0,.4)",
                borderRadius: "10px",
              },
              // position: "relative!important",
              boxShadow: 4,
              borderRadius: 2,
            }}
          >
            {details?.conversation.map((convo, index) => {
              if (convo.sender === "user") {
                return (
                  <Box key={index} bgcolor="white" padding={2}>
                    <Container
                      maxWidth="sm"
                      sx={{
                        display: "flex",

                        gap: 2,
                      }}
                    >
                      <Avatar
                        alt={"John Doe"}
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/dependent-images/" +
                          "John Doe"?.replace(" ", "-").toLowerCase() +
                          ".png"
                        }
                        {...stringAvatar("John Doe", {})}
                      />
                      <Typography>
                        {convo.message}{" "}
                        <Typography
                          component="span"
                          variant="caption"
                          sx={{ fontStyle: "italic", marginLeft: 1 }}
                        >
                          {convo.timestamp}
                        </Typography>
                      </Typography>
                    </Container>
                  </Box>
                );
              } else {
                const userIntent =
                  {
                    ...details?.conversation[index - 1]?.intent,
                    message: details?.conversation[index - 1]?.message,
                    intentIndex: details?.conversation[index - 1]?.eventIndex,
                  } || {};

                return (
                  <Fragment key={index}>
                    <RasaMessage
                      message={convo.message}
                      timestamp={convo.timestamp}
                      details={{ ...convo, user_intent: userIntent }}
                      senderId={senderId}
                      getConversationDetails={getConversationDetails}
                    />
                  </Fragment>
                );
              }
            })}

            <div ref={messagesEndRef} />
          </Box>
        ) : (
          <Box
            width="100%"
            height="100%"
            bgcolor="white"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: 4,
            }}
          ></Box>
        )}
      </Container>
    </SiccLayout>
  );
};

export default ConversationDetails;
