import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import "../../../assets/css/custom.css";
import CartCheckoutIcon from "@mui/icons-material/AssignmentOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Badge from "@mui/material/Badge";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setTotalPrice } from "../../../redux/cartReducer/actions";
import CheckoutMini from "features/eComm/CheckoutMini";
import Grid from "@mui/material/Grid";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const site = process.env.REACT_APP_SITE;
const storeId = process.env.REACT_APP_STORE_ID;

export default function DrawerRight(props) {
  const loginCustomerNumber = process.env.REACT_APP_LOGIN_CUSTOMER_NUMBER;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [mealBalance, setMealBalance] = useState();
  const [mealBalanceDollars, setMealBalanceDollars] = useState();
  const mBal = Math.abs(mealBalanceDollars / 100);
  const mBalance = mBal.toFixed(2);
  const [firstName, setFirstName] = useState(
    sessionStorage.getItem("firstName")
  );
  const [roomNumber, setRoomNumber] = useState(
    sessionStorage.getItem("roomNumber")
  );

  const { cartItems } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [totalItems, setTotalItems] = useState(0);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isMobile = useMediaQuery("(max-width:600px)");
  const drawerWidth = isMobile ? "100%" : "100%";

  const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      }),
    })
  );

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 0,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      background: "red",
    },
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  }));

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    zIndex: 2,
  }));

  useEffect(() => {
    const url =
      loginCustomerNumber == 1
        ? "https://ecommv2.servingintel.com/customer/login-customer-number/" +
        serverId +
        "/" +
        siteId +
        "/" +
        firstName +
        "/" +
        roomNumber
        : "https://ecommv2.servingintel.com/customer/login/" +
        serverId +
        "/" +
        siteId +
        "/" +
        storeId +
        "/" +
        firstName +
        "/" +
        roomNumber;
    axios
      .get(url)
      .then((response) => {
        const customer = response.data;
        if (
          customer.FirstName.toLowerCase() === firstName.toLowerCase() &&
          (customer.Address1.toLowerCase() === roomNumber.toLowerCase() ||
            customer.CustomerNumber == roomNumber)
        ) {
          setMealBalance(customer.MealCount);
          setMealBalanceDollars(
            customer.CreditLimit - customer.AvailableCredit
          );
        } else {
          window.location.href = site;
        }
      })
      .catch((error) => { });
  }, []);

  useEffect(() => {
    const checkCart = localStorage.getItem(`ajsliders_cart`);
    if (checkCart) {
      dispatch({ type: "SET_CART", payload: JSON.parse(checkCart) });
    } else {
      dispatch({ type: "SET_CART", payload: [] });
    }
  }, []);

  useEffect(() => {
    if (cartItems.length) {
      localStorage.setItem(`ajsliders_cart`, JSON.stringify(cartItems));
      let total = 0;
      let totalPrice = 0;
      cartItems.forEach((item) => {
        let mod_price = 0;
        item.modifiers.forEach((mod) => (mod_price += mod.DefaultPrice));
        total += item.quantity;
        totalPrice +=
          item.quantity * (item.DefaultPrice / 100 + mod_price / 100);
      });
      dispatch(setTotalPrice(totalPrice));
      setTotalItems(total);
    } else {
      setTotalItems(0);
      dispatch(setTotalPrice(0));
      localStorage.removeItem(`ajsliders_cart`);
    }
  }, [cartItems]);

  const MealCredit = (
    <React.Fragment>
      <CardContent style={{ marginTop: "-1rem", paddingTop: "0px !important" }}>
        <Typography variant="h4" component="div" align="center">
          {firstName}
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            className="checkoutDrawerRight"
            container
            spacing={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "50px",
              marginTop: "10px",
              marginLeft: "-14px",
            }}
          >
            <Grid>
              <Typography
                sx={{}}
                color="text.secondary"
                gutterBottom
                align="center"
                variant="h5"
              >
                Meal Credit Available:
              </Typography>
              <Typography
                variant="h4"
                component="div"
                align="center"
                fontWeight="500"
                fontSize="2.5rem !important"
              >
                {mealBalance}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                sx={{}}
                color="text.secondary"
                gutterBottom
                align="center"
                variant="h5"
              >
                Current Balance:
              </Typography>
              <Typography
                variant="h4"
                component="div"
                align="center"
                fontWeight="500"
                fontSize="2.5rem !important"
              >
                ${mBalance}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Typography
          sx={{}}
          color="text.dark"
          gutterBottom
          align="center"
          variant="h3"
        ></Typography>
        <CheckoutMini menuName={props.menuName} />
      </CardContent>
    </React.Fragment>
  );

  return (
    <Box id="draw" sx={{ fontSize: "large", display: "flex" }}>
      <CssBaseline />
      <AppBar
        bg="light"
        variant="light"
        expand="lg"
        position="fixed"
        open={open}
        sx={{
          backgroundColor: "transparent",
          marginRight: 2,
          top: 25,
          zIndex: 999999,
          maxWidth: "max-content",
        }}
      >
        <Toolbar>
          <Typography
            variant="h4"
            noWrap
            sx={{ fontSize: "large", flexGrow: 1 }}
            component="div"
          ></Typography>
          <IconButton
            id="iconLeft"
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{
              ...(open && { display: "none" }),
              backgroundColor: "#7a2733",
              transform: "scale(1.5)",
              "&:hover": { backgroundColor: "#16497c" },
            }}
          >
            <StyledBadge badgeContent={totalItems} color="secondary">
              <CartCheckoutIcon sx={{ fontSize: "12pt" }} />
            </StyledBadge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          fontSize: "large",
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            overflow: "unset",
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton
            id="checkoutMiniArrowIcon"
            onClick={handleDrawerClose}
            sx={{
              fontSize: "large",
              backgroundColor: "#7a2733 !important",
              transform: "scale(1.5)",
              margin: "10px",
              padding: "5px",
              color: "#FFF",
            }}
          >
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <Box sx={{ fontSize: "large", minWidth: 275 }}>
          <Card sx={{ border: "0px" }} variant="outlined">
            {MealCredit}
          </Card>
        </Box>

        <Divider />
        {/* <List>
          {['Settings', 'Profile'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>
    </Box>
  );
}